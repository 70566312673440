'use strict';

module.exports = {
    helpers: {
        recaptchaProvider: function (form) {
            return new Promise((resolve, reject) => {
                var $form = $(form);
                var $recaptcha = $form.find('[name="g-recaptcha-response"]');
                if ($recaptcha.length > 0) {
                    var siteKey = $recaptcha.data('sitekey');
                    var action = $form.find('[name="g-recaptcha-action"]').val();
                    grecaptcha.execute(siteKey, {
                        action: action
                    }).then(function (token) {
                        $recaptcha.val(token);
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        }
    }
};
