'use strict';

function triggerClickonRefinement ($ele) {
    $ele.closest('.refinement').find('.refinement-header').trigger('click');
}

module.exports = function () {
    /*
     * On load: In Mobile marketing nabnner is dynamic (enable/disabled),
     * this helps to handle below contents like minicart icon, search bar etc
     */
    if (!($('.mobile-promo-banner > div').length || $('.mobile-promo-banner').is(':visible'))) {
        $('body').addClass('mobile-promo-disabled');
    } else {
        $('body').removeClass('mobile-promo-disabled');    
    }

    /*
     * Ajax call for the Blogs Pagination
     */
    $(document).on('click', '.more-blogs', function (e) {
        var showMoreUrl = $(this).data('url');
        var target = $(".blogs-result-container");
        $.spinner().start();
        $.ajax({
            url: showMoreUrl,
            method: 'GET',
            success: function (data) {
                $.spinner().stop();
                $('.show-more-blog-content').remove();
                target.append(data);
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

	  /*
     * Event: updates the free shippping progress bar on minicart
     */
    $('body').on('cart:update', function (e, data) {

        if ($('.minicart-progressbar').length) {
            var $freeShippingQualifiedMsg = $('.free-shipping-qualified'),
                $progressBarWrapper = $('.progressbar-wrapper'),
                $progressBarIndicator = $progressBarWrapper.find('.progress-indicator');

            if (data && data.sliderAttributes != undefined) {
                if (data.sliderAttributes && !data.sliderAttributes.approachingShippingDiscountStatus && data.sliderAttributes.sliderAttributes && data.sliderAttributes.sliderAttributes[0] && data.sliderAttributes.sliderAttributes[0].minicartSliderPercentage !=undefined) {
                    var percent = parseInt(data.sliderAttributes.sliderAttributes[0].minicartSliderPercentage)
                    $freeShippingQualifiedMsg.addClass('d-none');
                    $progressBarWrapper.removeClass('d-none');
                    $progressBarIndicator.css('width', percent + '%');
                } else {
                    $freeShippingQualifiedMsg.removeClass('d-none');
                    $progressBarWrapper.addClass('d-none');
                }
            }

            // cart remove response with data.basket
            if (data && data.basket) {
                if (data.basket.sliderAttributes.approachingShippingDiscountStatus) {
                    $freeShippingQualifiedMsg.removeClass('d-none');
                    $progressBarWrapper.addClass('d-none');
                } else if (data.basket.sliderAttributes && data.basket.sliderAttributes.sliderAttributes && data.basket.sliderAttributes.sliderAttributes[0] && data.basket.sliderAttributes.sliderAttributes[0].minicartSliderPercentage != undefined){
                    var percent = parseInt(data.basket.sliderAttributes.sliderAttributes[0].minicartSliderPercentage)
                    $freeShippingQualifiedMsg.addClass('d-none');
                    $progressBarWrapper.removeClass('d-none');
                    $progressBarIndicator.css('width', percent + '%');
                } 
            }
        }
    });

    /*
    / Paypal Paylater cart amount update
    */
    $('body').on('cart:update', function (e, data) {
        if (data && data.totals && data.totals.grandTotal) {
            var grandTotal = data.totals.grandTotal;
            grandTotal = grandTotal.replace(/\$/g,'');
            const $creditMessage = $('.js_credit_message_cart');
            $creditMessage && $creditMessage.attr('data-pp-amount', grandTotal);
        }
    });

    /*
    / opens selected filter
    / triggers in providio search.js
    */
    $(document).on('search:filter:updated', function () {
        var $document= $(document),
        $refBody = $document.find('.refinement-body');

        // category and boolean type filter
        if ($refBody.find('.refinement-value').hasClass('selected') ) {
            triggerClickonRefinement($refBody.find('.refinement-value.selected'));
        }

        // price
        if ($refBody.find('input:radio').is(':checked')) {
            triggerClickonRefinement($refBody.find('input:radio:checked'));
        }

        // size
        if ($refBody.find('.size-btn').hasClass('bg-dark')) {
            triggerClickonRefinement($refBody.find('.size-btn.bg-dark'));
        }

        // color
        if ($refBody.find('.color-value').hasClass('selected')) {
            triggerClickonRefinement($refBody.find('.color-value.selected'))
        }
    });

    // change sold out text to ATC
    $('body').on('product:afterAttributeSelect', function (e,data) {
        if(!$('select[class*="select-"]').find('option:selected').prop('disabled') == true){
            $('.add-to-cart-global, .add-to-cart').text(data.data.resources.addtocart);
        }
        if (!data.data.product.available) {
            $('.add-to-cart-global, .add-to-cart').text(window.resources.properties.Soldout);
        }
    });

    // Working for device width between 575px & 769px 
    // Carousel need to have specific slide count mobile[1], tablet[3], desktop[4]
    if ( $(window).width() > 575 && $(window).width() < 769 ) {
        $('.carousel').addClass('custom-breakpoint');
    }

    $(window).on('scroll', function(){
        var position = $(window).scrollTop();
        if(position < 125) {
            $('.next-step-button').appendTo('.checkout-workflow-top-buttons');
        }
        else {
            $('.next-step-button').appendTo('.checkout-workflow-buttom-buttons');
        }
    });

    if (window.location.search.indexOf('paypalError') > -1) {
        var message = $('.checkout-btn.paypalshippingerror').attr('data-paypalshippingerror');
        if (message && $('.add-to-basket-alert').length === 0) {
            if ($('.add-to-cart-messages').length === 0) {
                $('body').append(
                    '<div class="add-to-cart-messages"></div>'
                );
            }
        
            $('.add-to-cart-messages').append(
                '<div class="alert alert-danger' + ' add-to-basket-alert text-center" role="alert">'
                + message
                + '</div>'
            );
        
            setTimeout(function () {
                $('.add-to-basket-alert').remove();
            }, 5000);
        }
        window.history.replaceState({}, '', window.location.href.split('&')[0]);
    }

    // Displaying promotion form at top in mobile only
    if ( $(window).width() < 575 ) {
        $('.promotion-form').appendTo('.promotion-xs');
        }
    else {
        $('.promotion-form').appendTo('.promotion-sm');
    }
};
