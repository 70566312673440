window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('base/components/spinner'));
    processInclude(require('base/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/customSearch'));
    processInclude(require('./product/wishlistHeart'));

    if (window.resources.preferences.isStorePickUpEnabled) {
        processInclude(require('bopis/storeLocator/storeLocator'));
    }

    if (window.resources.preferences.PLPAddToCart) {
        processInclude(require('./product/productTileAddToCart'));
    }

    if (window.resources.preferences.isGiftCertEnabled) {
	    processInclude(require('./giftcert/giftcert'));
    }

    if (window.resources.preferences.subscriptionProvider == 'ordergroove' && window.OG) {
        processInclude(require('orderGroove/cart/cart'));
        processInclude(require('./optins'));
    }

    if (window.resources.preferences.CustomQuantitySelector) {
        processInclude(require('./components/customqtyselector'));
    }
});

require('base/thirdParty/bootstrap');
require('./thirdParty/slick.min');
require('./thirdParty/easyzoom');