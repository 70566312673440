var giftCertificate = require('giftCertificate/giftcert/giftcert');

var base = require('base/product/base');
var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');


/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast

    if ($('.add-to-cart-messages').length === 0) {
        $('body').append(
            '<div class="add-to-cart-messages"></div>'
        );
    }

    $('.add-to-cart-messages').append(
        '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">' +
        response.message +
        '</div>'
    );

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
}

/**
 * Dynamically displaying the ECARD value
 */
function ecardAmountUpdate() {
    let val = $('#giftcert_purchase_amount').attr('value');
    let roundValue = Number(val).toFixed();
    var itemNumberField = document.getElementById('itemNo');
    if (itemNumberField) {
        itemNumberField.innerHTML = "ECARD" + roundValue;
    }
}

/**
 * Displaying the number of characters in the textArea field
 */
function textareaCounter() {
    var maxlength = 350;
    var currentLength = $('.js-textarea-with-count').val().length;
    $('.js-textarea-note').html(currentLength + "/" + maxlength);
    $('.js-textarea-with-count').on('input', function () {
        currentLength = $(this).val().length;
        if (currentLength >= maxlength) {
            $('.js-textarea-note').html(0 + "/" + 0).addClass('zero-count');
        } else {
            $('.js-textarea-note').html(currentLength + "/" + maxlength).removeClass('zero-count');
        }
    });
}

/**
 * Used to initiate ajax call and update the form
 * @param {Object} form - gift certificate form
 * @param {boolean} isUpdate - boolean value to check if it is update action
 * @param {HTMLElement} $addToCartBtn - Gift Card Add to Cart button
 * @return {boolean} false
 */
var updateGiftCertForm = function (form, isUpdate, $addToCartBtn) {
    var url = form.attr('action');
    var isGCCalendar = $('.giftcert').find('#calendar').length;
    var data = form.serialize();
    if (isGCCalendar) {
        var gcImageURL = $('.gift-details img').attr('src');
        data = data + '&gcImageURL=' + gcImageURL;
    }
    form.spinner().start();
    //$('form.giftcert').trigger('giftcert:submit', e);
    $addToCartBtn.attr('disabled', true);

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: data,
        success: function (data) {
            if (isGCCalendar) {
                formValidation(form, data);
                if (!data.success) {
                    $addToCartBtn.removeAttr('disabled');
                    return form.spinner().stop();
                }
                $('body').trigger('product:afterAddToCart', data);
                base.miniCartReportingUrl(data.reportingURL, data.error);
                handlePostCartAdd(data);
                var maxDate = $('.date-input #calendar').attr('min');
                form.find('input,textarea').val('');
                textareaCounter();
                if (data.customerFullName) {
                    form.find('#giftcert_purchase_from').val(data.customerFullName);
                }
                $('.date-input input').val(maxDate);
                var valSelected = $('.gift-amount li:first-child').val();
                var amountValue = $('.gift-amount li:first-child').html();
                if ($('.gift-amount li').hasClass('defaultSelected')) {
                    valSelected = $('.gift-amount li.defaultSelected').val();
                    amountValue = $('.gift-amount li.defaultSelected').html();
                }
                $('.giftvalue-update').attr('value', valSelected).html(amountValue);
                ecardAmountUpdate();
                $('.csrfTokenValue').val(data.csrf.token);
            } else {
                handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                base.miniCartReportingUrl(data.reportingURL, data.error);
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    if (!isUpdate) {
                        form.find('input,textarea').val('');
                        $('.csrfTokenValue').val(data.csrf.token);
                        $('.gift-card-amount .amount .gift-amount.active').removeClass('active');
                        $('.giftcert button[type="submit"]').html("SELECT AMOUNT").prop("disabled", true);
                        $addToCartBtn.removeAttr('disabled');
                        return false;
                    }
                    location.href = data.redirectUrl;
                }
            }
            $addToCartBtn.removeAttr('disabled');
            form.spinner().stop();
        },
        error: function (err) {
            if (isGCCalendar) {
                formValidation(form, data);
                form.spinner().stop();
            } else {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else if (isUpdate) {
                    $('.error-gift-certificate').html('');
                    createErrorNotification($('.error-gift-certificate'), err.responseJSON.errorMessage);
                    $('.html, body').animate({
                        scrollTop: $('.error-gift-certificate').offset().top
                    }, 1000);
                } else {
                    createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                }
                form.spinner().stop();
            }
            $addToCartBtn.removeAttr('disabled');
        }
    });
    return false;
};

/**
 * Used to initiate ajax call and check gift card balance
 * @param {Object} form - check balance form
 */
var checkBalance = function (form) {
    var url = form.attr('action');
    form.spinner().start();
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                formValidation(form, data);
            } else {
                $('#gift-balance-msg').html(data.giftCertificate.balance).removeClass('red');
            }
        },
        error: function (err) {
            form.spinner().stop();
            $('#gift-balance-msg').html(err.responseJSON.error).addClass('red');
        }
    });
};

var giftcardamount = $(".gift-card-amount .amount").attr("value") || $('.gift-amount li').attr('value');
module.exports = {
    formSubmit: function () {
        // eslint-disable-next-line no-unused-vars
        if (giftcardamount > 1) {
            $('body').on('click', '.amount', function (e) {
                $('.gift-amount').removeClass('active');
                $(this).find('.gift-amount').addClass('active');
                var amount = ($(this).attr('value'));
                $('#giftcert_purchase_amount').val(amount);
                $('.gift-price-amout').html('$' + parseInt(amount).toFixed(2));
                $('.custom-amount').removeClass('active');
                $('.entered-amount').removeClass('open');
                $('.cart-wishlist-action .btn-primary').html('ADD TO CART');
                $('.btn-primary').removeAttr('disabled');
                // changing amount range to selected amount value in mobile
                amount = $(this).html();
                $('.price-range').html('<span>' + amount + '<span>');
            });

            $('body').on('click', '.custom-amount', function () {
                if ($(this).hasClass('active')) {
                    $('.custom-amount').removeClass('active');
                    $('.entered-amount').removeClass('open');
                } else {
                    $(this).addClass("active");
                    $('.entered-amount').addClass('open');
                }
            });


            // highlight pre-defined gift amount during manual entry of amount
            var amountArray = [];
            var textboxValue;
            var giftCertMinAmount = $('.giftCertMinvalue').data('value');
            var giftCertMaxAmount = $('.giftCertMaxvalue').data('value');
            var frmtGiftCertMinAmount = $('.formatedGiftCertMinvalue').data('value');
            var frmtGiftCertMaxAmount = $('.formatedGiftCertMaxvalue').data('value');
            var priceRange = $('.price-range span').html();
            $('.amount').each(function () {
                var amountValue = $(this).attr('value');
                amountArray.push(amountValue);
            });

            $('.entered-amount').on('keyup', 'input', function () {
                textboxValue = $(this).val();
                $('.price-range span').html('$' + textboxValue);
                if(textboxValue) {
                    $('.gift-price-amout').html('$' + parseInt(textboxValue).toFixed(2));
                } else {
                    $('.gift-price-amout').html('$' + parseInt(0).toFixed(2));
                }
                if ($(this).val() === '') {
                    $('.price-range span').html(priceRange);
                }
                for (var i = 0; i < amountArray.length; i++) {
                    // textboxValue = $(this).val();

                    if (textboxValue.indexOf('.') !== -1) {
                        textboxValue = parseFloat(textboxValue);
                    } else {
                        textboxValue = parseInt(textboxValue);
                    }

                    textboxValue = textboxValue.toString();
                    if (amountArray[i] === textboxValue) {
                        $(".amount[value='" + textboxValue + "']").find('.gift-amount').addClass('active');
                        break;
                    } else {
                        $('.gift-amount').removeClass('active');
                    }
                }

                if ($('.entered-amount input').val() < giftCertMinAmount || $('.entered-amount input').val() > giftCertMaxAmount) {
                    $('.highlight span').css('color', '#e30046');
                    $('.highlight i').addClass('red');
                    if ($('.entered-amount input').val() < giftCertMinAmount) {
                        $('.highlight span').text(`The minimum amount is ${frmtGiftCertMinAmount}`);
                    }
                    if ($('.entered-amount input').val() > giftCertMaxAmount) {
                        $('.highlight span').text(`The maximum amount is ${frmtGiftCertMaxAmount}`);
                    }
                } else {
                    $('.highlight span').css('color', '#929292');
                    $('.highlight i').removeClass('red');
                    $('.highlight span').text(`The minimum amount is ${frmtGiftCertMinAmount}`);
                }
            });


            $('.entered-amount #giftcert_purchase_amount').change(function () {
                $('.gift-card-amount .amount .gift-amount.active').removeClass('active');
                var amt = $(this).val();
                if (amt < giftCertMinAmount) {
                    $('#giftcert_purchase_amount').val(giftCertMinAmount);
                    $('.gift-price-amout').html('$' + parseInt(giftCertMinAmount).toFixed(2));
                    $('.price-range span').html(`${frmtGiftCertMinAmount}`);
                    $('.cart-wishlist-action .btn-primary').html('ADD TO CART');
                    $('.btn-primary').removeAttr('disabled');
                    $('.entered-amount input').css('border', '2px solid #e30046');
                    setTimeout(function () {
                        $('.entered-amount input').css('border', '1px solid #929292');
                    }, 3000);
                } else if (amt > giftCertMaxAmount) {
                    $('#giftcert_purchase_amount').val(giftCertMaxAmount);
                    $('.gift-price-amout').html('$' + parseInt(giftCertMaxAmount).toFixed(2));
                    $('.price-range span').html(`${frmtGiftCertMaxAmount}`);
                    $('#form-amount-error').html(`Gift Certificate can only be purchased with a minimum of ${giftCertMinAmount} and maximum of ${giftCertMaxAmount}`).show();
                    $('.cart-wishlist-action .btn-primary').html('ADD TO CART');
                    $('.btn-primary').removeAttr('disabled');
                    $('.entered-amount input').css('border', '2px solid #e30046');
                    setTimeout(function () {
                        $('.entered-amount input').css('border', '1px solid #929292');
                    }, 3000);
                } else {
                    $('.cart-wishlist-action .btn-primary').html('ADD TO CART');
                    $('.btn-primary').removeAttr('disabled');
                }
            });


            // eslint-disable-next-line consistent-return
            $('textarea').keyup(function () {
                if (this.value.length > 300) {
                    return false;
                }
                $('#remainingC').html((300 - this.value.length) + ' characters left');
            });

            if ($('#giftcert_purchase_amount').val() === '') {
                $('#giftcert_purchase_amount').html('ADD TO CART');
                // $('.btn-primary').removeAttr('disabled');
            }

            $('form.giftcert').submit(function (e) {
                var $addToCartBtn = $('form.giftcert').find('.addto-cartbtn');
                var form = $(this);
                e.preventDefault();
                if ($('#form-amount-error').length > 0) {
                    $('#form-amount-error').empty();
                }
                updateGiftCertForm(form, false, $addToCartBtn);
            });
            $(document).on('keydown', '.gift-amount', function (e) {
                // checking enter key

                if (event.keyCode === 13) {
                    $(this).trigger('click');
                }
            });

            if (window.enableGiftCardPDPApplePayButton) {
                window.enableGiftCardPDPApplePayButton();
            }
            if ($('.giftcert').find('#calendar').length) {
                textareaCounter();
            }
        }
    },

    addToBasket: function () {
        if (giftcardamount == 'null' || giftcardamount == undefined) {
            $('form.giftcert').submit(function (e) {
                var form = $(this);
                var $btnSubmitForm = $(form).find('.addto-cartbtn');
                e.preventDefault();
                updateGiftCertForm(form, false, $btnSubmitForm);
            });
        }
    },
    /**
     * Initializing the minimum date for calendar object
     */
    giftcerticiateAmount: function () {
        if (document.getElementById("calendar")) {
            var today = new Date().toISOString().substr(0, 10);
            document.getElementById("calendar").value = today;
            var maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() + 1);
            document.getElementById("calendar").max = maxDate.toISOString().substr(0, 10);
            var minDate = new Date().toISOString().split('T')[0];
            document.getElementById("calendar").setAttribute("min", minDate);
        }
    },
    /**
     * Updating the gcImage on click event
     */
    updateGCImage: function () {
        $('.giftcertificate:first').addClass('active');
        $('.gift-certificate-main div.giftcertificate').on('click', function (e) {
            e.preventDefault();
            $('.giftcertificate').removeClass('active');
            $(this).addClass('active');
            var gcimage = $(this).find('img').addClass('active');
            var gcMainImage = $('.gift-details img').not('.giftcertificate img');
            $(gcMainImage).attr('src', gcimage.attr('src'));
        });
    },
    /**
     * Updating the dropdown value on change
     */
    giftcerticiateDropdown: function () {
        var valSelected = $('.gift-amount li:first-child').val();
        var amountValue = $('.gift-amount li:first-child').html();
        if ($('.gift-amount li').hasClass('defaultSelected')) {
            valSelected = $('.gift-amount li.defaultSelected').val();
            amountValue = $('.gift-amount li.defaultSelected').html();
        }
        $('.giftvalue-update').attr('value', valSelected).html(amountValue);
        ecardAmountUpdate();
        $(document).on('click', '.giftvalue-update', function () {
            $(this).closest(".amountdropdown-list").find('ul').toggle();
            return false;
        });

        var allOptions = $(".gift-amount").children('li');
        $('.gift-amount').on("click", "li", function () {
            allOptions.removeClass('selected');
            $(this).addClass('selected');
            $('.giftvalue-update').html($(this).html());
            $('.giftvalue-update').attr('value', $(this).val());
            $('.gift-amount').toggle();
            ecardAmountUpdate();
        });

        /**
         * Collapsing the dropdown when clicked outside of the select box.
         */
        $('body').on('click', function () {
            if ($('#gift-amount').is(':visible') && $('#gift-amount').length > 0) {
                $('.gift-amount').hide();
                return false;
            }
        });
    },
    //restrict special chars in recipient input field
    recipientInput: function () {
        $('#giftcert_purchase_recipient').on('input', function () {
            var position = this.selectionStart,
                pattern = /[^a-z0-9 ]/gi,
                enteredValue = $(this).val();
            if (pattern.test(enteredValue)) {
                $(this).val(enteredValue.replace(pattern, ''));
                position--;
            }
            this.setSelectionRange(position, position);
        });
    },
    updateGiftCertificate: giftCertificate.updateGiftCertificate,
    checkGiftCertBalance: giftCertificate.checkGiftCertBalance


};