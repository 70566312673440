'use strict';

var base = require('base/product/base');

function quickViewImagesSlick() {
    var $quickviewImages = $(document).find('.product-quickview').find('.primary-images');
    $quickviewImages.find('.product-image-carousel').addClass('order-0 col-lg-12');
    $quickviewImages.find('.product-image-carousel').removeClass('col-lg-10');
    $quickviewImages.find('.product-thumbnail-slick').addClass('order-3 col-lg-12 pt-4');
    $quickviewImages.find('.product-thumbnail-slick').removeClass('col-lg-2');

    var $quickviewSlick = $(document).find('.product-image-carousel');
    var $quickviewSlickOptions = {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        infinite: true,
        dots: false,
        draggable: true,
        asNavFor: '.product-thumbnail-slick'
    };

    var $slidesToShowValue = 4;
    var $slidesToScrollValue = 1;

    /* $childElements is calculated before initializing slick, based on the number of child elements $slidesToShowValue and $slidesToScrollValue is updated and then slick is initialized. */
    var $childElements = $('.product-thumbnail-slick').children().length;
    if ($slidesToShowValue > $childElements) {
        $slidesToShowValue = $childElements;
        $slidesToScrollValue = $childElements;
    }

    /* Initializing Tumbnail slick and product images slick in quickview  */
    var $quickviewThumbnailSlick = $(document).find('.product-thumbnail-slick');
    var $quickviewTumbnailSlickOptions = {
        asNavFor: '.product-image-carousel',
        slidesToShow: $slidesToShowValue,
        slidesToScroll: $slidesToScrollValue,
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        arrows: false,
        infinite: false
    };

    // each for set items
    $.each($quickviewSlick, function () {
        if (!$(this).hasClass('slick-initialized')) {
            $(this).slick($quickviewSlickOptions);
        }
    });

    $.each($quickviewThumbnailSlick, function () {
        if (!$(this).hasClass('slick-initialized')) {
            $(this).slick($quickviewTumbnailSlickOptions);
        }
    });

    if ($(window).width() > 1024 && window.resources.preferences.quickViewZoomEnabled == 'true') {
        $('.easyzoom').easyZoom();
    }
}

function pdpImagesSlick() {
    var $pdpSlick = $(document).find('.product-image-carousel');
    var $pdpSlickOptions = {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        infinite: true,
        dots: false,
        draggable: true,
        asNavFor: '.product-thumbnail-slick',
        responsive: [{
            breakpoint: 768,
            settings: {
                dots: $('.product-image-carousel').attr('enable-mobile-carousel-dots') == 'true'
            }
        }]
    };

    var $slidesToShowValue = 5;
    var $slidesToScrollValue = 1;

    /* $childElements is calculated before initializing slick, based on the number of child elements $slidesToShowValue and $slidesToScrollValue is updated and then slick is initialized. */
    var $childElements = $('.product-thumbnail-slick').children().length;
    if ($slidesToShowValue > $childElements) {
        $slidesToShowValue = $childElements;
        $slidesToScrollValue = $childElements;
    }

    /* Initializing thumbnail slick and product images slick in PLP page */
    var $thumbnailSlick = $(document).find('.product-thumbnail-slick');
    var $tumbnailSlickOptions = {
        asNavFor: '.product-image-carousel',
        slidesToShow: $slidesToShowValue,
        slidesToScroll: $slidesToScrollValue,
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        arrows: $('.product-image-carousel').attr('enable-thumbnail-arrows') == 'true',
        vertical: true,
        infinite: false
    };

    $.each($thumbnailSlick, function () {
        if (!$(this).hasClass('slick-initialized')) {
            $(this).slick($tumbnailSlickOptions);
        }
    });

    $.each($pdpSlick, function () {
        if (!$(this).hasClass('slick-initialized')) {
            $(this).slick($pdpSlickOptions);
        }
    });

    /* To get the latest image and display on mouseout when user clicked on color swatches */
    swatchesHoverImage();
}

function createCarousel(response, $productContainer) {
    var $imageshtml = $('<div>').append($.parseHTML(response.pdpImages));
    var pdpDesktopImages = $imageshtml.find('.carousel-container');

    $productContainer.find('.carousel-container').replaceWith(pdpDesktopImages);

    if ($('.product-quickview').length) {
        module.exports.quickViewImagesSlick();
    } else {
        module.exports.pdpImagesSlick();
    }

    if ($(window).width() > 1024 && !$('#quickViewModal:visible').length) {
        $('.easyzoom').easyZoom();
    }
}

function storeSelectStyles (response) {
    var editWishlistProductModal = document.getElementById('editWishlistProductModal');
    var editProductModal = document.getElementById('editProductModal');
    if (editProductModal || editWishlistProductModal ) {
        return null;
    }
    $('.add-to-cart').prop('disabled', true);
    if ($('#delivery-options-store').length > 0 && $('#delivery-options-home').length > 0) {
        if (response && response.product && !response.product.isBikeCategory) {
            $('#delivery-options-home').prop('checked',true);
            $('#delivery-options-store').prop('checked',false);
            $('#delivery-options-store').prop('disabled',true);
        }
        $('.selected-store-availability').attr('data-status','select-style');
        $('.selected-store-availability').text('Select Styles for Availability');
    }
}

function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    var checkSizeSelected;
    if ($('.select-size').hasClass('size-change')) {
        checkSizeSelected = !$('.size-change span').hasClass('selected')
    } else {
        checkSizeSelected = $('.select-size').prop("selectedIndex") == 0
    }
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div class="selectforstock">' + response.resources.info_selectforstock + '</div></li>';
        storeSelectStyles(response);
        var selectedAttrCount = 0;
        var unSelectedAttr = [];
        for (var a = 0 ; a < response.product.variationAttributes.length ; a++) {
            unSelectedAttr.push(response.product.variationAttributes[a].id);
            for (var b = 0 ; b < response.product.variationAttributes[a].values.length ; b++) {
                if (response.product.variationAttributes[a].values[b].selected == true) {
                    unSelectedAttr.pop(response.product.variationAttributes[a].id);
                    selectedAttrCount++;
                    break;
                }
            }
        }
        if (response.product.variationAttributes.length != selectedAttrCount) {
            for (var i = 0 ; i < unSelectedAttr.length ; i++) {
                $('.select-' + unSelectedAttr[i]).prop("selectedIndex", 0);
            }
        }

    } else if (($('.select-color').prop("selectedIndex") == 0 || checkSizeSelected)) {
        availabilityValue = '<li><div class="selectforstock">' + response.resources.info_selectforstock + '</div></li>';
        response.product.readyToOrder = null;
        storeSelectStyles(response);

    } else {
        var availabilityClassName = response.product.available ? 'in-stock inStock' : 'out-of-stock outOfStock';
        if (response.product.availability && response.product.availability.orderSupplierText && response.product.availability.messages && response.product.availability.messages.length > 0 && response.product.availability.messages[0] == response.product.availability.orderSupplierText) {
            availabilityClassName = 'available-at-supplier';
        }
        availabilityMessages.forEach(function (message) {
            // condition to show lower case text of availability message
            if ($('.availability').hasClass('availability-msg-lowercase')) {
                availabilityValue += '<li><div class="' + availabilityClassName + '">' + message.toLowerCase() + '</div></li>';
            } else {
                availabilityValue += '<li><div class="' + availabilityClassName + '">' + message + '</div></li>';
            }
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': ' +
                    attribute.value + '</div>';
            });
        }
    });

    return html;
}

function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

function updateQuantities(quantities, $productContainer, product) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var quantitySelector = getQuantitySelector($productContainer);
        if (!window.resources.preferences.CustomQuantitySelector) {
            var optionsHtml = quantities.map(function (quantity) {
                var selected = quantity.selected ? ' selected ' : '';
                return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                    selected + '>' + quantity.value + '</option>';
            }).join('');
            quantitySelector.empty().html(optionsHtml);
        } else {
            // Update the Custom Quantiry selector
            quantitySelector.data('url', quantities[0].url);
            var customQtyMessage = product.availability && product.availability.customQtyMessage || false;

            if (customQtyMessage) {
                $productContainer.find('.customQtyMessage').text(customQtyMessage).removeClass("d-none");
            } else {
                $productContainer.find('.customQtyMessage').addClass("d-none");
            }

            if (product.customMaxOrderQuantity) {
                $productContainer.find('.quantity-select').data('end', product.customMaxOrderQuantity);
            }
            $productContainer.find('.quantity-select').data('preqty', product.selectedQuantity);
        }
    }
}

function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);

            if ($('.swatch-attr-style').is(':visible')) {
                $swatchButton.addClass('selected-swatch');
            }
        } else {
            $attrValue.parent().removeClass('selected-swatch');
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');
        // Disable for color name swatch style
        if ($('.swatch-attr-style').is(':visible')) {
            $attrValue.parent().removeClass('selectable unselectable');
            $attrValue.parent().addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        }

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');

        // add disable attr when color swatch in dropdown
        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        } else {
            $attrValue.attr('disabled', false);
        }
    });
}

function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    if ($('.size-change').is(':visible') && $('.size-change').length) {
        var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' .size-value:first');
    } else {
        var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    }
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value.replace(/"/g, "'") + '"]');
        // This selectable-oos-product is used in diet direct to enable the PDP functionality for OOS products
        if (!$('.attribute').hasClass('selectable-oos-product')) {
            $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');
        }

        // add disable attr after ajax
        if (!attrValue.selectable) {
            if (!$('.attribute').hasClass('selectable-oos-product')) {
                $attrValue.attr('disabled', true);
            }
            // condition to show oos text with display value
            if ($('.attribute').hasClass('remove-oos-additional-text')) {
                $attrValue.text(attrValue.displayValue);
            } else {
                $attrValue.text(attrValue.displayValue + ' (Out Of Stock)');
            }
        } else {
            $attrValue.text(attrValue.displayValue);
        }
    });
}

function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    createCarousel(response, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length ?
            $('.prices .price', $productContainer) :
            $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product,
            $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

function updateDeliveryOptions(response) {
    var editWishlistProductModal = document.getElementById('editWishlistProductModal');
    var editProductModal = document.getElementById('editProductModal');
    if (editProductModal || editWishlistProductModal || ($('#delivery-options-store').length == 0 && $('#delivery-options-home').length == 0)) {
        return null;
    }
    var uuid = response.product.uuid;
    $('.pickupInStore-deliveryOptions .delivery-option').attr('name','delivery_option_'+uuid);
    if ($('.selectforstock').length > 0) {
        storeSelectStyles(response);
    } else if (response.product.productStoreInventory != null && response.product.productStoreInventory > 0) {
        $('#delivery-options-store').prop('disabled',false);
        $('.selected-store-availability').attr('data-status','store-in-stock');
        $('.selected-store-availability').text('In Stock');
        $('.add-to-cart').prop('disabled', false);
    } else if (response.storeId != null) {
        if (!response.product.isBikeCategory) {
            $('#delivery-options-home').prop('checked',true);
            $('#delivery-options-store').prop('checked',false);
            $('#delivery-options-store').prop('disabled',true);
        }
        $('.selected-store-availability').attr('data-status','store-error');
        $('.selected-store-availability').text('Not Available');
        if (response.product.available) {
            $('.add-to-cart').prop('disabled', false);
        } else {
            $('.add-to-cart').prop('disabled', true);
        }
    }
}

function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer
        });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateDeliveryOptions(data);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer, data.product);
                $('body').trigger('product:afterAttributeSelect', {
                    data: data,
                    container: $productContainer
                });
                $('body').trigger('product:onChangingVariant', {
                    data: data,
                    container: $productContainer
                });
                $.spinner().stop();
                if (window.resources.preferences.listrakCartridgeEnabled === 'true') {
                    try {
                        // eslint-disable-next-line no-undef
                        if (_ltk) {
                            var sessionSku = data.product.id;
                            /* Add the product browse if we have it. */
                            if (sessionSku != null && sessionSku !== 'null' && sessionSku.length > 0) {
                                // eslint-disable-next-line no-undef
                                _ltk.Activity.AddProductBrowse(sessionSku);
                            }
                            /* Submit the activity through client side JS to the endpoint. */
                            // eslint-disable-next-line no-undef
                            _ltk.Activity.Submit();
                        }
                    } catch (error) {
                        console.log('_ltk is not available');
                    }
                }
                var quickViewContainer = document.getElementById('quickViewModal');
                var quickViewModalCart = document.getElementById('editProductModal');
                var editWishlistProductModal = document.getElementById('editWishlistProductModal');
                if (!quickViewContainer && !quickViewModalCart && !editWishlistProductModal) {
                    window.history.pushState(null, '', data.pdpWindowUrl);
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

// To change carousel primary image on hovering the color swatches
function swatchesHoverImage() {
    $('body').on('mouseover', '.color-attribute:not(".selected-swatch")', function () {
        var thumbimage = $(this).data('thumb-main');
        $('.product-image-carousel .slick-active img').attr('src', thumbimage).addClass('hover-swatch');
    }).on('mouseleave', '.color-attribute:not(".selected-swatch")', function () {
        var prevImage = $('.color-attribute.selected-swatch').data('thumb-main');
        $('.product-image-carousel .slick-active img').attr('src', prevImage).removeClass('hover-swatch');
    });
}

function sizeChart() {
    $('body').on('click', '.product-quickview .size-chart a:not(".nav-link")', function (e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.attr('href');
        var $prodSizeChart = $this.closest('.size-chart').find('.size-chart-collapsible');
        $this.closest('.size-chart').append('<div class="size-chart-collapsible"></div>');
        if ($('#size-chart-modal').length !== 0) {
            $('#size-chart-modal').remove();
        }

        //cloning the modal from isml
        var $modalAppend = $this.closest('.size-chart').find('.size-chart-modal').clone().appendTo('body');
        var $modalShow = $modalAppend.attr('id', 'size-chart-modal');
        if ($prodSizeChart.is(':empty')) {
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    var sizeChartDetail = $prodSizeChart.append(data.content);
                    $('#size-chart-modal .modal-body').html(sizeChartDetail);
                    $('#size-chart-modal').modal('show').addClass('show');
                }
            });
        }
    });

    //Removing modal div on close and modal hide
    $('body').on('hidden.bs.modal', '#size-chart-modal', function (e) {
        $('#size-chart-modal').remove();
    });

    $('body').on('click', '#size-chart-modal .close', function (e) {
        $('#size-chart-modal').modal('hide');
    });
}

module.exports = $.extend({}, base, {
    attributeSelect: attributeSelect,
    pdpImagesSlick: pdpImagesSlick,
    quickViewImagesSlick: quickViewImagesSlick,
    sizeChart: sizeChart,
    swatchesHoverImage: swatchesHoverImage,

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect($(this).attr('data-url'), $productContainer);
        });

        $('body').on('click', '.primary-images .slick-paging-btn', function () {
            swatchesHoverImage();
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });

        $(document).on('click', '.size-change span', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            $('.size-change span.selected').removeClass('selected');
            $(this).addClass('selected');
            attributeSelect($(this).data('value'), $productContainer);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });

        $(document).on('change', '.select-color', function (e) {
            if ($(this).val() == 'null') {
                var AvailabilityMsg = '<li><div class="selectforstock">Select Styles for Availability</div></li>';
                $('.list-unstyled.availability-msg').empty().html(AvailabilityMsg);
                $('.add-to-cart').prop('disabled', true);
            }
        });

    }

});
