'use strict';

$(document).delegate('.js-size-swatch-list-button', 'click', function (event) {
    $(this).prev('.js-plp-size-list').removeClass('d-none');
});

$(document).on('mouseover', '.js-product-tile', function () {
    if ($(this).find(".js-select-button").not(".selected").length && $(this).find(".js-select-button").not(".unselectable").length) {
        $(this).find(".js-select-button").removeClass('d-none');
    }

    if ($(this).find(".js-select-button").hasClass("unselectable")) {
        $(this).find(".js-pt-add-to-cart").removeClass('d-none');
    }
}).on('mouseleave', '.js-product-tile', function () {
    $(this).find(".js-select-button").addClass('d-none').removeClass('selected');
    $(this).find('.js-plp-size-list').addClass('d-none');
    $(this).find('.js-pt-add-to-cart').addClass('d-none');
});

$(document).delegate('.js-product-tile', 'click', function (event) {
    $(this).prev('.js-plp-size-list').removeClass('d-none');
});

$(document).delegate('.js-select-button', 'click', function (event) {
    $(document).find('.js-plp-size-list').find('ul').find('li').removeClass('selected');
    $('.js-plp-size-list').addClass('d-none');
    $(this).prev('.js-plp-size-list').removeClass('d-none');
    $(this).find(".button-content").html($(this).data('select'));
});


module.exports = function () {
    var $body = $('body');
    /**
     * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
     * @param {string} response - ajax response from clicking the add to cart button
     */
    function handlePostCartAdd(response) {
        $('.minicart').trigger('count:update', response);
        var messageType = response.error ? 'alert-danger' : 'alert-success';
        // show add to cart toast
        if (response.newBonusDiscountLineItem &&
            Object.keys(response.newBonusDiscountLineItem).length !== 0) {
            chooseBonusProducts(response.newBonusDiscountLineItem);
        } else {
            if ($('.add-to-cart-messages').length === 0) {
                $('body').append(
                    '<div class="add-to-cart-messages"></div>'
                );
            }

            $('.add-to-cart-messages').append(
                '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">' +
                response.message +
                '</div>'
            );

            setTimeout(function () {
                $('.add-to-basket-alert').remove();
            }, 5000);
        }
    }

    $(document).delegate('.js-swatch-item-link', 'click', function (event) {
        event.preventDefault();
        var $this = $(this);

        if ($this.find('img').hasClass('swatch-selected')) {
            return;
        }

        $this.closest('.js-product-tile').spinner().start();
        var $currentSwatch = $(event.currentTarget);
        var url = $(event.currentTarget).attr('href');
        $('.js-plp-size-list').addClass('d-none');

        var $productTile = $currentSwatch.closest('.js-product-tile');
        $productTile.find('.js-pt-add-to-cart').data('pid', '');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json'
        }).done(function (response) {
            var product = response.product;
            $productTile.find(".swatch").removeClass('swatch-selected');
            $this.find(".swatch").addClass('swatch-selected');
            processSwatchValues(product, $productTile);
            processNonSwatchValues(product, $productTile);
            $this.closest('.js-product-tile').spinner().stop();
            var tileImageURL = 'large' in product.images && product.images.large.length > 0 && 'url' in product.images.large[0] ? product.images.large[0].url : '';
            $this.closest('.js-product-tile').find('.tile-image').attr('src', tileImageURL);
        });
    });

    function processSwatchValues(product, $productContainer) {
        product.variationAttributes.forEach(function (attr) {
            if (attr.id === 'color') {
                console.log(attr);
                attr.values.forEach(function (attrValue) {
                    var $attrValue = $productContainer
                        .find('[data-attr-value="' + attrValue.value + '"]');
                    console.log(attrValue.url);
                    if (attrValue.selected) {
                        $attrValue.find('img').addClass('swatch-selected');
                    } else if (attrValue.url) {
                        $attrValue.attr('href', attrValue.url);
                    }
                });
            }
        });
    }

    function processNonSwatchValues(product, $productContainer) {
        if (product.readyToOrder && product.online) {
            $productContainer.find('.js-pt-add-to-cart')
                .data('pid', product.id)
                .removeClass('d-none');
        } else {
            product.variationAttributes.forEach(function (attr) {
                if (attr.id !== 'color') {
                    var $select = $productContainer.find('.js-plp-size-list ul');
                    attr.values.forEach(function (attrValue) {
                        var $sizeSwatch = $select.find('[data-attr-value="' + attrValue.value + '"]');
                        if (!attrValue.selectable) {
                            $sizeSwatch.addClass('unselectable');
                        } else {
                            $sizeSwatch.removeClass('unselectable');
                        }
                        $sizeSwatch
                            .attr('data-url', attrValue.url)
                            .attr('disabled', !attrValue.selectable);
                    });
                }
            });
        }
    }

    $(document).on('product:afterAddToCart', function () {
        $('.js-minicart-trigger').trigger('focusin');
    });

    $body.on('click', '.js-size-swatch-list-button', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $productTile = $this.closest('.js-product-tile');

        $(document).find('.js-plp-size-list').find('ul').find('li').removeClass('selected');

        if ($this.hasClass('unselectable')) {
            return;
        }
        $this.closest('li').addClass('selected');
        var href = $this.attr('data-url');
        $.get(href, function (res) {
            if (!res.product.available || res.product.productType === 'master') {
                $this.addClass('unselectable').prop('disabled', true);
                $productTile.find(".js-select-button").removeClass('d-none');
                $productTile.find('.js-pt-add-to-cart')
                    .addClass('d-none')
                    .data('pid', '');
                return;
            }
            $productTile.find('.js-pt-add-to-cart')
                .data('pid', res.product.id)
                .removeClass('d-none')
                .addClass('success-color');
            $productTile.find(".js-select-button").addClass('d-none selected');
        });
    });

    $body.on('click', '.js-pt-add-to-cart', function () {
        $.spinner().start();

        $body.trigger('product:beforeAddToCart', this);

        var $this = $(this);

        var form = {
            pid: $this.data('pid'),
            quantity: 1
        };

        $this.trigger('updateAddToCartFormData', form);

        $.ajax({
            url: resources.urls.AddToCart,
            method: 'POST',
            data: form,
            success: function (data) {
                if (window.resources.preferences.segmentEnabled) {
                    var pageURL = window.location.href;
                    try {
                        $('body').trigger('segment:event', {
                            callback: 'addToCart',
                            data: {response: data, form: form, segment:data.segment, eventName:'Product Added', pageURL: pageURL}
                        });
                    } catch (e) {
                        console.log(e);
                    }
                }
                $('.js-plp-size-list').addClass('d-none');
                $this.addClass('d-none');
                if (!$this.prev('.js-select-button').hasClass("unselectable")) {
                    $this.data('pid', '');
                }
                $.spinner().stop();
                handlePostCartAdd(data);
                $body.trigger('product:afterAddToCart', data);
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};
