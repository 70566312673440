'use strict';

var cart = require('../cart/cart');
var baseProduct = require('../product/base');
var baseWishlist = require('wishList/components/miniCart');
var updateMiniCart = true;

function wishlistEditModalSlick () {
    $('body').on('editwishlistproduct:ready', function () {
       // cart.quickViewImagesSlick();
       baseProduct.quickViewImagesSlick();
    });
}
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();

    if (data) {
        var status = data.success ? 'alert-success' : 'alert-danger';
        
        if (data.wishlistCount && data.wishlistCount >= 0) {
            $('.wishlist span.wishlistCount').html(data.wishlistCount);
        }

        if ($('.add-to-wishlist-messages').length === 0) {
            $('body').append('<div class="add-to-wishlist-messages "></div>');
        }
        $('.add-to-wishlist-messages')
            .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

        setTimeout(function () {
            $('.add-to-wishlist-messages').remove();
        }, 1500);
        var $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
        var itemToMove = {
            actionUrl: $targetElement.data('action'),
            productID: $targetElement.data('pid'),
            productName: $targetElement.data('name'),
            uuid: $targetElement.data('uuid')
        };
        $('body').trigger('afterRemoveFromCart', itemToMove);
        setTimeout(function () {
            $('.cart.cart-page #removeProductModal').modal();
        }, 2000);
    }
}
baseWishlist.moveToWishlist=function()
    {
        $('body').on('click', '.product-move .move', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var pid = $(this).data('pid');
            var loginUrl= $(this).attr('data-login');
            var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
            var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
            var uuid = $(this).attr('data-uuid');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }
    
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal,
                    uuid: uuid
                },
                success: function (data) {
                    displayMessageAndRemoveFromCart(data);
                    $.spinner().start();
                    var $targetButton = $('button[data-pid="' + pid + '"]').closest('.product-info').find('.custom-delete').find('.cart-delete-confirmation-btn');
                    if($targetButton.length>0)
                    $targetButton.trigger('click');
                    var $isRegistered = $('#myaccount').find('.registered-user-message');
                    if($isRegistered.length==0)
                    {
                        window.location.href= loginUrl;
                    }
                    $.spinner().stop();
                },
                error: function (err) {
                    displayMessageAndRemoveFromCart(err);
                }
            });
        });
    }

module.exports = {
    baseMiniCart: function () {
        cart();

        $('.minicart').on('count:update', function (event, count) {
            if (count && $.isNumeric(count.quantityTotal)) {
                $('.minicart .minicart-quantity').text(count.quantityTotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems || count.cart.resources.minicartCountOfItems,
                    title: count.minicartCountOfItems || count.cart.resources.minicartCountOfItems
                });
            }
        });

        $('.minicart .minicart-link').on('click', function (e) {
            e.preventDefault();

            if ($('.search:visible').length === 0) {
                return;
            }
            var url = $('.minicart').data('action-url');
            var count = parseInt($('.minicart .minicart-quantity').text(), 10);

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart) {
                    $('.minicart .popover').addClass('show');
                    return;
                }

                $('.minicart .popover').addClass('show');
                $('.minicart .popover').spinner().start();
                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    updateMiniCart = false;
                    $.spinner().stop();
                });
            }
        });
        $('body').on('touchstart click', function (e) {
            if ($('.minicart').has(e.target).length <= 0) {
                $('.minicart .popover').removeClass('show');
            }
        });
        $('.minicart').on('click', '.minicart-close-btn', function (event) {
            if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
                || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
                || $('body').hasClass('modal-open')) {
                event.stopPropagation();
                return;
            }
            $('.minicart .popover').removeClass('show');
        });
        $('body').on('change', '.minicart .quantity', function () {
            if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                location.reload();
            }
        });
        $('body').on('product:afterAddToCart', function () {
            updateMiniCart = true;
            if ($('.minicart .popover').hasClass('show')) {
                return;
            }
            //opens minicart and closes
            $(document).find('.minicart .minicart-link').trigger('click');
            setTimeout(function () {
                $('.minicart .popover').removeClass('show');
            }, 4500);
        });
        $('body').on('cart:update', function () {
            updateMiniCart = true;
        });
    },
    
    selectAttribute: cart.selectAttribute,
    pdpImagesSlick: cart.pdpImagesSlick,
    moveToWishlist: baseWishlist.moveToWishlist,
    initWishlistSlick: wishlistEditModalSlick
};
