'use strict';

/**
 * A function to call the OrderGroove-PurchasePostTracking endpoint with the current OG optins.
 */
function onOptinChanged() {
    var ogValue = $('.product-detail .attributes').find('og-offer').attr('data-attr');
    $.ajax({
        url: window.OrdergrooveTrackingUrl,
        method: 'POST',
        data: {
            tracking: JSON.stringify(window.OG.getOptins()),
            pid: ogValue
        },
        success: function (data) {
            // We want to trigger a quantity change event even though the quantity isn't changing
            // so that we execute the same logic that refreshes discounts when the quantity changes
            $('.quantity-form > .quantity').trigger('change');

            //Updating the PDP price without changing the event types.
            if (data.product && data.product.renderedPrice) {
                $('.price').empty().append(data.product.renderedPrice);
            }
            
            // this is added to show/hide paypal button in minicart & cart
            if (data && !data.autoShip) {
                $('.isautoship').removeClass('d-none');
            } else {
                $('.isautoship').addClass('d-none');
            }
        }
    });
}

function customizeOgFrequencyStatusMessage() {
    try {
        var elementsList = document.querySelectorAll('og-frequency-status');

        if (elementsList.length > 0) {
            Array.prototype.forEach.call(elementsList, function (elem) {
                var shadow = elem.shadowRoot;
                var childNodes = Array.from(shadow.childNodes);

                for (var index = 0; index < childNodes.length; index++) {
                    var childNode = childNodes[index];
                    if (childNode.nodeName === 'SPAN') {
                        var cnn = childNode.childNodes;
                        for (var index = 0; index < cnn.length; index++) {
                            var cn = cnn[index];
                            if (cn.nodeName === 'SLOT' && cn.name === 'subscribed') {
                                var innerText = cn.innerText.split(' ');
                                //var frequency = innerText[2] + ' ' + innerText[3];
                                var frequency = cn.innerText;
                                cn.innerHTML = '<span><b>Delivery Every: </b></span><span>' + frequency + '</span>';
                            }
                        }
                    }
                }
            });
        }
    } catch (e) {
        console.error(e);
    }
}

module.exports = function () {
    if (window.resources.preferences.subscriptionProvider == 'ordergroove') {
        if (!window.OrdergrooveLegacyOffers) {
            window.OG.addOptinChangedCallback(onOptinChanged);
        }
        // customizeOgFrequencyStatusMessage();
    }
};
