'use strict';
var baseProduct = require('../product/base');

function errormessage(message) {
    if (message && $('.add-to-basket-alert').length === 0) {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }
    
        $('.add-to-cart-messages').append(
            '<div class="alert alert-danger' + ' add-to-basket-alert text-center" role="alert">'
            + message
            + '</div>'
        );
    
        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

module.exports = {
    'pdpQuantitySelect': function () {
        $('body').on('blur', '.customQty .quantity-select', function () {
            var $this = $(this);
                var $productContainer = $this.closest('.product-detail');
            var qty = parseInt($this.val());
            var dataEnd = $this.data('end');
            var updateAllowed = true;
            var customQtyMessage;

            if (qty > parseInt(dataEnd)) {
                updateAllowed = false;
                customQtyMessage = "The maximum you may purchase is " + parseInt(dataEnd);
            }

            if (!updateAllowed) {
                $productContainer.find('.customQtyMessage').text(customQtyMessage).removeClass("d-none");
                // disables ATC button if max qty reached
                if(!$productContainer.find('button.add-to-cart').is(':disabled')) 
                    $productContainer.find('button.add-to-cart').attr("disabled", true);
            } else {
                $productContainer.find('.customQtyMessage').addClass("d-none");
            }

            if (parseInt($(this).val()) > 0) {
                if (updateAllowed) {
                if (!$productContainer.length) {
                    $productContainer = $this.closest('.modal-content').find('.product-quickview');
                }
                var url = $this.data('url') + '&quantity=' + $this.val();
                baseProduct.attributeSelect(url, $productContainer);

                }
            } else {
                // if val is empty, changes to min data-qty
                $(this).val($(this).data('qty'));
            }
        });
    },

    prodStepperEvents: function () {
        $('body').on('click', '.customQty .customqty-stepper .qty-minus', function () {
            var inputElement = $(this).closest('.customqty-stepper').find('input');
            var val = parseInt(inputElement.val());
            val--;
            if (val > 0) {
                inputElement.val(val).trigger('blur');
            }

        }).on('click', '.customQty .customqty-stepper .qty-plus', function () {
            var inputElement = $(this).closest('.customqty-stepper').find('input');
            var val = parseInt(inputElement.val());
            val++;
            inputElement.val(val).trigger('blur');

        }).on('blur', '.customQty .customqty-stepper input', function () {
            if ($(this).val() !== '') {
                var $val = parseInt($(this).val());
                $(this).val($val > 0 ? $val : 0);
            }
			/* if (!parseInt($(this).val()) > 0) {
               $(this).val($(this).data('qty'));
            }  */
        });
    },
    cartStepperEvents: function () {
        $('body').on('click', '.line-item-quantity .customqty-stepper .qty-minus', function () {
            var inputElement = $(this).closest('.customqty-stepper').find('input');
            var val = parseInt(inputElement.val());
            val--;
            if (val > 0) {
                inputElement.val(val).trigger('change');
            }
        }).on('click', '.line-item-quantity .customqty-stepper .qty-plus', function () {
            var inputElement = $(this).closest('.customqty-stepper').find('input');
            var val = parseInt(inputElement.val());
            var incrementAllowed = true;
            var triggerRequired = true;
            var wishlist = (inputElement.hasClass('wishlistCustomQty')) ? true : false;
         
            if (wishlist) {
                if (inputElement.is('[data-end]') && val >= parseInt(inputElement.data('end'))) {
                    var customQtyMessage = "The maximum you may purchase is " + parseInt(inputElement.data('end'));
                    errormessage(customQtyMessage);
                }
                triggerRequired = false;
            }
            if (incrementAllowed) {
                val++;
                inputElement.val(val);
                var triggerRequired = (inputElement.hasClass('wishlistCustomQty')) ? false : true;
                if (triggerRequired) {
                    inputElement.trigger('change');
                }
            }
        }).on('blur', '.line-item-quantity .customqty-stepper .quantity', function () {        
            var inputElement = $(this);
            if (inputElement.hasClass('wishlistCustomQty')) {
                if (inputElement.is('[data-end]') && inputElement.val() >= parseInt(inputElement.data('end'))) {
                    var customQtyMessage = "The maximum you may purchase is " + parseInt(inputElement.data('end'));
                    errormessage(customQtyMessage);
                }
            }
            if ($(this).val() !== '') {
                var $val = parseInt($(this).val());
                $(this).val($val > 0 ? $val : 0);
            }
			/* if (!parseInt($(this).val()) > 0) {
               $(this).val($(this).data('qty'));
            }  */
        }).on('blur', '.quantity-select', function() {
            var pid = $(this).attr('data-pid');
            $('.cart-and-ipay input[data-pid=' + pid + ']').attr('data-selected-quantity',$(this).val())
        });
    }
};
