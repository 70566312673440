'use strict';

/**
 * calculate the header height
 * and same height is applied to main content
 * as header is sticky
 */
function calculateMainTopSpace () {
    var headerHeight = $('.site-header').outerHeight();
    $('#maincontent').css('padding-top', headerHeight);
}

module.exports = {

    search: function () {
        $('.mobile-search_btn').on('click', function () {
            $('body').toggleClass('site-search-active');
            if ($('.search').hasClass('d-none')) {
                $('.search').removeClass('d-none');
            } else {
                $('.search').addClass('d-none');
            }
        });

        $('.site-search .reset-button').on('click', function () {
            $('.mobile-search_btn').trigger('click');
        });

        $('body').on('product:statusUpdate', function (e, data) {
            //updates product name and brand
            if ($('.product-detail .product-name').length) {
                $('.product-name').text(data.productName);
            }
            if ($('.product-detail .brand-name').length) {
                $('.brand-name').text(data.brand);
            }
        });
    },

    header: function () {
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > $('.site-header').height()) {
                $('body').addClass('minify-header');
            } else {
                $('body').removeClass('minify-header');
            }
        });

        // closes nav on click of modal
        $('body').on('touchstart click', '.modal-background', function () {
            if ($('.menu-toggleable-left').hasClass('in')) {
                $('.menu-toggleable-left .close-button button').trigger('click');
            }
        });

        calculateMainTopSpace();

        $('body').on('keyup', '.filter-section-hide', function (e) {
            if(e.keyCode == 13){
                $(this).trigger('click');
            }
        });
    },

    footer: function () {
        // back to top button
        $(window).scroll(function () {
            if ($(this).scrollTop() > 150) {
                $('.scroll-top').removeClass('d-none');
                $('.scroll-top').addClass('d-block');
            } else {
                $('.scroll-top').removeClass('d-block');
                $('.scroll-top').addClass('d-none');
            }
        });
    },

    minicart: function () {
        // closes minicart on x mark click
        $('body').on('touchstart click', '.btn-close', function () {
            $('.minicart .popover').removeClass('show');
        });

        // closes select box on hover of minicart, if it is focused
        $('.minicart .popover, .main-menu .navbar ul li').on('mouseenter focusin touchstart', function () {
            if ($('select:focus').length) {
                $('select').blur();
            }
        });

        $('body').on('cart:update', function () {
            /*
            *  removes modal after PLI remove from basket,
            *  OOTB cartdeleteconfirmation dialog is auto triggered
            */
            $('body > .modal-backdrop').remove();
        });
    },

    scrollHideBody: function () {
        //Adding and removing class to body for hiding body scroll
        $('.navbar-toggler').on('click', function () {
            $('body').addClass('modal-open');
        });

        $('body').on('click', '.menu-toggleable-left .close-button button', function () {
            $('body').removeClass('modal-open');
        });

        $('.navbar-nav').on('click', '.close-button', function (e) {
            $('body').removeClass('modal-open');
        });
    },

    slickCarousel: function () {
        var $slickContainer = $(document).find('.slick-carousel-container');
        var $videoComponent = $(document).find('.experience-pd_assets-videoComponent');
        var $currentPlayerType, $currentVideoURL, $updatedPlayerType, $updatedVideoURL;

        var $slickOptions = {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: true,
            infinite: true,
            dots: false,
            draggable: true,
            prevArrow: '<div class="slick-prev d-none d-md-block"><span class="fa fa-angle-left"></span><span class="sr-only">Prev</span></div>',
            nextArrow: '<div class="slick-next d-none d-md-block"><span class="fa fa-angle-right"></span><span class="sr-only">Next</span></div>'
        };

        var pauseVideo = function () {
            $('#' + $currentPlayerType).attr('src', '');
            $('#' + $currentPlayerType).attr('src', $currentVideoURL);
            $updatedVideoURL = $('.slick-current').find("iframe").attr("src");
            $updatedPlayerType = $('.slick-current').find("iframe").attr("id");
            $currentVideoURL = $updatedVideoURL;
            $currentPlayerType = $updatedPlayerType;
        };

        if (!$slickContainer.hasClass('slick-initialized')) {
            $slickContainer.slick($slickOptions);
        }

        $('.slick-carousel-container').on('init', function (event, slick) {
            if ($videoComponent.length > 0) {
                $currentVideoURL = $('.slick-current').find("iframe").attr("src");
                $currentPlayerType = $('.slick-current').find("iframe").attr("id");
            }
        });

        $('.producttile-carousel').slick({
            draggable: true,
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            focusOnSelect: false,
            infinite: true,
            dots: false,
            responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 544,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.slick-carousel-container').on('afterChange', function () {
            pauseVideo();
        });

    }

};
