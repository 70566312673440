
'use strict';
var base = require('base/storeLocator/storeLocator');

function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    // order discounts
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    // shipping discounts
    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    // products discounts
    if (data.totals.productLevelDiscountTotal && data.totals.productLevelDiscountTotal.value > 0) {
        $('.product-discount').removeClass('hide-product-discount');
        $('.product-discount-total').empty()
            .append('- ' + data.totals.productLevelDiscountTotal.formatted);
    } else {
        $('.product-discount').addClass('hide-product-discount');
    }

    // ---- Start Affirm code section -----
    if (window.resources.preferences.bnplProvider == 'affirm') {
        var totalCalculated = data.totals.grandTotal.substr(2).replace(/,/g, '');
        /* Affirm block for refreshing promo message */
        $('.affirm-as-low-as').attr('data-amount', (totalCalculated * 100).toFixed());
        affirm.ui.refresh();
    }
    // ---- End Affirm code section -----

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').empty().append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
        $('.cart-error').empty();
    }
}

function updateQtyOption (data) {

    if (data && data.maxOrderQuantity && data.pid) {
        var qtySelect = $('.quantity-form > .quantity[data-pid='+ data.pid +']');
        if (qtySelect.length) {
            var preSelectQty = parseInt(qtySelect.data('pre-select-qty'), 10);
            qtySelect.find("option").remove();
            for (let index = 0; index < parseInt(data.maxOrderQuantity,10); index++) {
                let optionVal = parseInt(index,10) + 1;
                qtySelect.append('<option '+ (preSelectQty == optionVal ? 'selected': '') +' value="' + optionVal + '">' + optionVal + '</option>');
            }
        }

    }
}

/**
 * Uses google maps api to render a map
 */
function maps() {
    var map;
    var infowindow = new google.maps.InfoWindow();

    // Init U.S. Map in the center of the viewport
    var latlng = new google.maps.LatLng(37.09024, -95.712891);
    var mapOptions = {
        scrollwheel: false,
        zoom: 4,
        center: latlng
    };

    map = new google.maps.Map($('.map-canvas')[0], mapOptions);
    var mapdiv = $('.map-canvas').attr('data-locations');

    mapdiv = JSON.parse(mapdiv);

    var bounds = new google.maps.LatLngBounds();

    // Customized google map marker icon with svg format
    var markerImg = {
        path: 'M13.5,30.1460153 L16.8554555,25.5 L20.0024287,25.5 C23.039087,25.5 25.5,' +
            '23.0388955 25.5,20.0024287 L25.5,5.99757128 C25.5,2.96091298 23.0388955,0.5 ' +
            '20.0024287,0.5 L5.99757128,0.5 C2.96091298,0.5 0.5,2.96110446 0.5,5.99757128 ' +
            'L0.5,20.0024287 C0.5,23.039087 2.96110446,25.5 5.99757128,25.5 L10.1445445,' +
            '25.5 L13.5,30.1460153 Z',
        fillColor: '#0070d2',
        fillOpacity: 1,
        scale: 1.1,
        strokeColor: 'white',
        strokeWeight: 1,
        anchor: new google.maps.Point(13, 30),
        labelOrigin: new google.maps.Point(12, 12)
    };

    Object.keys(mapdiv).forEach(function (key) {
        var item = mapdiv[key];
        var lable = parseInt(key, 10) + 1;
        var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
        var marker = new google.maps.Marker({
            position: storeLocation,
            map: map,
            title: item.name,
            icon: markerImg,
            label: { text: lable.toString(), color: 'white', fontSize: '16px' }
        });

        marker.addListener('click', function () {
            infowindow.setOptions({
                content: item.infoWindowHtml
            });
            infowindow.open(map, marker);
        });

        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);
    });
    // Fit the all the store marks in the center of a minimum bounds when any store has been found.
    if (mapdiv && mapdiv.length !== 0) {
        map.fitBounds(bounds);
    }
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    var $resultsDiv = $('.results');
    var $mapDiv = $('.map-canvas');
    var hasResults = data.stores.length > 0;

    if (!hasResults) {
        $('.store-locator-no-results').show();
    } else {
        $('.store-locator-no-results').hide();
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    $mapDiv.attr('data-locations', data.locations);

    if ($mapDiv.data('has-google-api')) {
        maps();
    } else {
        $('.store-locator-no-apiKey').show();
    }

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
    }
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function searchStores(element) {
    var dialog = element.closest('.in-store-inventory-dialog');
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    var $form = element.closest('.store-locator');
    var radius = $('.results').data('radius');
    var url = $form.attr('action');
    var urlParams = { radius: radius };
    var city = '';
    var postalCode = $form.find('[name="postalCode"]').val();
    if (postalCode && postalCode != '') {
        postalCode = postalCode.toUpperCase();
        var isValidPostalCode = (new RegExp($('#store-postal-code').attr('data-rule-pattern'))).test(postalCode);
        if (!isValidPostalCode) {
            postalCode = '';
            city = $form.find('[name="postalCode"]').val();
        }
    } else {
        postalCode = 'showAll';
    }

    var payload = $form.is('form') ? $form.serialize() : { postalCode: postalCode, city: city };

    url = appendToUrl(url, urlParams);

    $.ajax({
        url: url,
        type: $form.attr('method'),
        data: payload,
        dataType: 'json',
        success: function (data) {
            spinner.stop();
            updateStoresResults(data);
        }
    });
    return false;
}

function updateDeliveryOptions(response, pageLocation) {
    if ($('#delivery-options-store').length == 0 && $('#delivery-options-home').length == 0) {
        return null;
    }
    var stateCode = response.store.stateCode ? ', '+response.store.stateCode : '';
    var postalCode = response.store.postalCode ? ', '+response.store.postalCode : '';
    var storeHTML = response.store.address1 + '<br>' + response.store.city +stateCode + postalCode ;
    $('.btn-find-in-store').empty().html('Change Store');
    $('.find-a-store').removeClass('find-a-store');
    if ($('.selectforstock').length > 0) {
        if (!response.isBikeCategory) {
            $('#delivery-options-home').prop('checked',true);
            $('#delivery-options-store').prop('checked',false);
            $('#delivery-options-store').prop('disabled',true);
        }
        $('.selected-store-availability').attr('data-status','select-style');
        $('.selected-store-availability').text('Select Styles for Availability');
        $('.selected-store-address').empty().html(storeHTML);
        $('.selected-store-address').attr('data-store-id', response.store.ID);
        $('.add-to-cart').prop('disabled', true);
    } else if (response.isBikeCategory && response.isBikeCategory == true) {
        if (response.productStoreInventory != null && response.productStoreInventory > 0) {
            $('#delivery-options-home').prop('checked',false);
            $('#delivery-options-store').prop('disabled',false);
            $('#delivery-options-store').prop('checked',true);
            $('.selected-store-availability').attr('data-status','store-in-stock');
            $('.selected-store-availability').text(window.resources.properties.inStockMessage);
            $('.availability .availability-msg li').html('<div class="in-stock inStock">'+window.resources.properties.inStockMessage+'</div>');
            $('.selected-store-address').empty().html(storeHTML);
            $('.selected-store-address').attr('data-store-id', response.store.ID);
            $('.add-to-cart').prop('disabled', false);
        } else {
            if (!response.isBikeCategory) {
                $('#delivery-options-home').prop('checked',true);
                $('#delivery-options-store').prop('checked',false);
                $('#delivery-options-store').prop('disabled',true);
            }
            $('.selected-store-availability').attr('data-status','store-error');
            $('.selected-store-availability').text(window.resources.properties.outOfStockMessage);
            $('.availability .availability-msg li').html('<div class="out-of-stock outOfStock">'+window.resources.properties.outOfStockMessage+'</div>');
            $('.selected-store-address').empty().html(storeHTML);
            $('.selected-store-address').attr('data-store-id', response.store.ID);
            $('.add-to-cart').prop('disabled', true);
        }
    } else if (response.productStoreInventory != null && response.productStoreInventory > 0) {
        $('#delivery-options-home').prop('checked',false);
        $('#delivery-options-store').prop('disabled',false);
        $('#delivery-options-store').prop('checked',true);
        $('.selected-store-availability').attr('data-status','store-in-stock');
        $('.selected-store-availability').text(window.resources.properties.inStockMessage);
        $('.selected-store-address').empty().html(storeHTML);
        $('.selected-store-address').attr('data-store-id', response.store.ID);
        $('.add-to-cart').prop('disabled', false);
    } else if (pageLocation == 'pdp') {
        if (!response.isBikeCategory) {
            $('#delivery-options-home').prop('checked',true);
            $('#delivery-options-store').prop('checked',false);
            $('#delivery-options-store').prop('disabled',true);
        }
        $('.selected-store-availability').attr('data-status','store-error');
        $('.selected-store-availability').text('Not Available');
        $('.selected-store-address').empty().html(storeHTML);
        $('.selected-store-address').attr('data-store-id', response.store.ID);
        if (response.productInventory && response.productInventory > 0) {
            $('.add-to-cart').prop('disabled', false);
        } else {
            $('.add-to-cart').prop('disabled', true);
        }
    } else if (pageLocation == 'cart') {
        window.location.reload();
    }
}

base.selectStore= function () {
    $('body').on('click', '.store-locator-container .select-store', (function (e) {
        e.preventDefault();
        var selectedStore = $(':checked', '.results-card .results');
        var storeId = selectedStore.val();
        if (!storeId) {
            storeId = $(this).attr('data-store-id');
        }
        var storeDetailsHtml = selectedStore.siblings('label').find('.store-details').html();
        if (!storeDetailsHtml) {
            storeDetailsHtml = $('#'+storeId+  ' .store-details').html()
        }
        var pid = null;
        if ($('span.product-id').length > 0 && $('.cart.cart-page').length  == 0) {
            pid = $($('span.product-id')[0]).text();
        }
        var data = {
            storeID: storeId,
            pid: pid,
        };
        var url = $(this).attr('data-url');
        $('#storeFindModal').modal('hide');
        if (storeId && url) {
            $('.select-store').text('Select Store');
            $(this).text('Selected');
            $.ajax({
                url: url,
                method: 'POST',
                data: data,
                success: function (response) {
                    if (!response.error) {
                        $('#findInStoreModal button.close').click();
                        var pageLocation;
                        var htmlString = '<!-- Modal -->'
                        + '<div class="modal fade" id="storeDetails" tabindex="-1" role="dialog">'
                        + '<div class="modal-dialog">'
                        + '<!-- Modal content-->'
                        + '<div class="modal-content">'
                        + '<div class="modal-body"></div>'
                        + '</div>'
                        + '</div>'
                        + '</div>';
                        $('body').append(htmlString);
                        var store = '<div class="selected-storedata"><b>Your Selected Store</b><br/>' + storeDetailsHtml + '</div>';
                        var storeData = '<div class="select-store-alert text-center">'+store+'</div>';
                        $('.selected-store-in-modal').html(store);
                        $('#storeDetails').modal('show');
                        $('#storeDetails .modal-body').html(storeData);
                        $('.header-store-name').html(response.storeName);
                        if ($('.pdp-storedetails').hasClass('d-none')) {
                            $('.pdp-storedetails').removeClass('d-none');
                        }

                        if ($('.wishlistItemCards').length > 0 && $('.wishlist-page').length > 0 && $('.wishlist-page').attr('data-wishlist-page-url')) {
                            window.location.href = $('.wishlist-page').attr('data-wishlist-page-url');
                        } else if($('.wishlistItemCards').length > 0){
                            location.reload();
                        } else if ($('.btn.add-to-cart').length > 0 || $('.add-to-cart-global').length > 0) {
                            pageLocation = 'pdp';
                        } else if ($('.cart.cart-page').length > 0) {
                            pageLocation = 'cart';
                        } else {
                            pageLocation = 'stores';
                        }
                        updateDeliveryOptions(response, pageLocation);
                    }
                }
            });
        }
    }));

    //Hiding Selected store modal after 2 secs
    $('body').on('shown.bs.modal', '#storeDetails', function (e) {
        setTimeout(function () {
            $('#storeDetails').modal('hide');
        }, 2000);
    });
};

base.changeRadius = function () {
    $('body').on('change', '.store-locator-container .select-radius', function () {
        var searchKeys = $('.results').data('search-key');
        var radius = $(this).val();
        var city = '';
        var postalCode = $('#store-postal-code').val();
        postalCode = postalCode ? postalCode.toUpperCase() : '';
        var isValidPostalCode = (new RegExp($('#store-postal-code').attr('data-rule-pattern'))).test(postalCode);
        if (!isValidPostalCode) {
            postalCode = '';
            city = $('#store-postal-code').val();
        }
        var url = $(this).data('action-url');
        var urlParams = {};
        if (postalCode || city) {
            urlParams = {
                radius: radius,
                postalCode: postalCode,
                city: city
            };
        } else if (searchKeys.lat && searchKeys.long) {
            urlParams = {
                radius: radius,
                lat: searchKeys.lat,
                long: searchKeys.long
            };
        }

        url = appendToUrl(url, urlParams);
        var dialog = $(this).closest('.in-store-inventory-dialog');
        var spinner = dialog.length ? dialog.spinner() : $.spinner();
        spinner.start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                spinner.stop();
                updateStoresResults(data);
            }
        });
    });
};

base.search = function () {
    $('body').on('click', '.store-locator-container .storelocator-search', function (e) {
        e.preventDefault();
        searchStores($(this));
    });
},


base.deliveryOptions = function () {
    $('body').on('click', '.card .delivery-options-home', (function (e) {
        var storeId = $(this).attr('data-store-id');
        var pid = $(this).attr('data-pid');
        var url = $(this).attr('data-url');
        var uuid = $(this).attr('data-uuid');
        var data = {
            storeId: storeId,
            pid: pid
        };
        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            success: function (response) {
                //console.log('successfully selected!');
                var storePickup = $('#shippingMethods option[data-shipping-id=005]').text().trim();
                if($("select#shippingMethods").val() == storePickup) {
                    var defaultValue = ($("select#shippingMethods option")[0]).text;
                    $("select#shippingMethods").val(defaultValue).change();
                    $('#shippingMethods option[data-shipping-id=005]').attr('disabled', 'true');
                    $('#shippingMethods option[data-shipping-id=005]').addClass('d-none');
                    $('select#shippingMethods').removeAttr('disabled');
                }
                updateQtyOption(response);
                updateCartTotals(response.basket);
                updateApproachingDiscounts(response.basket.approachingDiscounts);
                validateBasket(response.basket);
            }
        });
    }));
    $('body').on('click', '.card .delivery-options-store', (function (e) {
        var storeId = $(this).attr('data-store-id');
        var pid = $(this).attr('data-pid');
        var url = $(this).attr('data-url');
        var data = {
            storeId: storeId,
            pid: pid
        };
        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            success: function (response) {
                var isPickUpStore = true;
                $(".delivery-options-store").each(function(){
                    if(!$(this).prop('checked')) {
                        isPickUpStore = false;
                    }
                });
                if (isPickUpStore) {
                    $('#shippingMethods option[data-shipping-id=005]').removeAttr('disabled');
                    var storePickup = $('#shippingMethods option[data-shipping-id=005]').text().trim();
                    $("select#shippingMethods").val(storePickup).change();
                    $('#shippingMethods option[data-shipping-id=005]').removeClass('d-none');
                    $('select#shippingMethods').prop('disabled',true);
                }
                updateQtyOption(response);
                updateCartTotals(response.basket);
                updateApproachingDiscounts(response.basket.approachingDiscounts);
                validateBasket(response.basket);
            }
        });
    }));

    $('body').on('click', '.pdp-delivery-options-home', (function (e) {
        if ($('.availability .availability-msg .out-of-stock').length > 0 || $('.availability .availability-msg .selectforstock').length) {
            $('.add-to-cart').prop('disabled', true);
        } else {
            $('.add-to-cart').prop('disabled', false);
        }
    }));
    $('body').on('click', '.pdp-delivery-options-store', (function (e) {
        if ($(this).closest('.card.product-info').length > 0 && $(this).closest('.wishlist-page').length > 0) {
            return; // do nothing on wishlist page. because we have only delivery to store option on wishlist page
        }
        if ($('.selected-store-availability').attr('data-status') == 'store-in-stock') {
            $('.add-to-cart').prop('disabled', false);
        } else {
            $('.add-to-cart').prop('disabled', true);
        }
    }));
};

base.findInStoreModal = function () {
    var processInclude = require('base/util');
    var $findInStoreModal = $('#findInStoreModal');

    $findInStoreModal.on('shown.bs.modal', function (e) {
        var $findInStoreButton = $('.store-init');
        var $variationValue = $('.js-attributes-container').find('button.selected').attr('data-attr-value');

        $('.js-variation-value').html($variationValue);

        if (!$findInStoreButton.hasClass('initialized')) {
            $findInStoreButton.addClass('initialized');
            var link = $(e.relatedTarget);
            $.ajax({
                url: link.attr('href'),
                success: function (data) {
                    $findInStoreModal.find('.modal-body').empty().html(data);
                    var storeLocator = require('../storeLocator/storeLocator');
                    processInclude(storeLocator);
                }
            });
        }
    });
};

base.findaStore = function () {
    $('body').on('click', '.find-a-store', (function (e) {
        $('.btn-find-in-store').click();
    }));
};

module.exports = base;