'use strict';

var base = require('base/components/clientSideValidation');

base.invalid = function () {
    $('form input, form select, .validate-textarea').on('invalid', function (e) {
        e.preventDefault();
        //Update to state if user selectedcountry without states
        if ($('#state option').length <= 1 && $('#state').closest('.form-group').parent().hasClass('d-none')) {
            $('#state option').val('N/A');
            $('#state option').text('N/A');
            $('#state').val('N/A').change();
        }
        this.setCustomValidity('');
        var validationMessage = this.validationMessage;
        if (!this.validity.valid) {
            $(this).addClass('is-invalid');
            if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                validationMessage = $(this).data('pattern-mismatch');
            }
            if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) &&
                $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if ((this.validity.tooLong || this.validity.tooShort) &&
                $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if (this.validity.valueMissing && $(this).data('missing-error')) {
                validationMessage = $(this).data('missing-error');
            }
            if (this.id == 'zipCode') {
                var zipCodeMessage = $('#zipCode').attr('data-missing-error');
                validationMessage = zipCodeMessage;
            }

            $(this).parents('.form-group').find('.invalid-feedback')
                .text(validationMessage);
        }

        //Showing error message on submit
        var stateSelect = $('#state');
        var stateval = stateSelect.val() ? stateSelect.val().trim() : '';
        if (stateval == '' || stateval == null) {
            $(stateSelect).addClass('is-invalid');
            var stateCodeMessage = stateSelect.attr('data-missing-error');
            $('#state').closest('.form-group').find('.invalid-feedback').text(stateCodeMessage);
        }
    });
};

module.exports = base;
