'use strict';

var scrollAnimate = require('base/components/scrollAnimate');
var recaptchaHelpers = require('recaptcha/recaptcha');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message').empty()
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

module.exports = {
    backToTop: function () {
        $('.back-to-top').click(function () {
            scrollAnimate();
        });
    },
    subscription: function () {
        $('.subscribe-email').on('click', function (e) {
            e.preventDefault();
            var form = $(this).closest('form');
            var url = $(this).data('href');
            var emailId = $('input[name=hpEmailSignUp]').val();
            var button = $(this);
            var mcEmail = form.find("input[name$='_email']");
            if (mcEmail && mcEmail.length > 0 && !(/^[\w.%+-]+@[\w.-]+\.[\w]{2,7}$/.test(mcEmail.val() || ''))) {
                displayMessage({msg: mcEmail.attr('parse-error')}, button);
                return;
            }


            $.spinner().start();
            var recaptchaPromise = recaptchaHelpers.helpers.recaptchaProvider(form);
            recaptchaPromise.then(() => {
                $(this).attr('disabled', true);
                var formData = form.serialize();
                if (emailId) {
                    var formDataArray = formData.split('&');
                    formDataArray.push('emailId=' + emailId);
                    formData = formDataArray.join('&');
                }

                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: formData,
                    success: function (data) {
                        // used to trigger segment events
                        if (data.success && window.resources.preferences.segmentEnabled) {
                            try {
                                $('body').trigger('segment:event', {
                                    callback: 'emailSignUp',
                                    data: data
                                });
                            } catch (e) {
                                console.log(e);
                            }
                        }
                        //if subscribed, clear the email field
                        if (data.success && mcEmail && mcEmail.length > 0) {
                            mcEmail.val('');
                        }
                        displayMessage(data, button);
                    },
                    error: function (err) {
                        displayMessage(err, button);
                    }
                });
            });
        });
    }
};
