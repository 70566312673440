'use strict';

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon, type) {
    $.spinner().stop();
    var status;
    if (data.success) {
    	status = type == 'add' ? 'alert-success' : 'alert-danger';
        if (icon.hasClass('fa-heart-o')) {
            icon.removeClass('fa-heart-o').addClass('fa-heart');
        } else {
            icon.removeClass('fa-heart').addClass('fa-heart-o');
        }
    } else {
        status = 'alert-danger';
    }

    if(data.productCount != null) {
    	$('.wishlist .wishlist-count').html(data.productCount);
    	if(data.productCount > 0) {
    		$('.right-nav .wishlist-inner').addClass('wishlist-active');
    	}
    	else {
    		$('.right-nav .wishlist-inner').removeClass('wishlist-active');
    	}
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append(
            '<div class="add-to-wishlist-messages "></div>'
        );
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

module.exports = {
    addToWishlist: function () {
    	$('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            var $el = $(this);
            var icon = $(this).find($('i'));
            var type = $(this).hasClass('wishlist-active') ? 'remove' : 'add';
            var url = type == 'remove' ? $(this).data('removefromwishlist') : $(this).data('addtowishlist');
            var method = (type == 'remove') ? 'get' : 'post';
            var pid = $(this).closest('.product').data('pid');
            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            var gtmData = $(this).closest('.product-tile').data('gtmdata');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: method,
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    if (data.wishlistCount >= 0) {
                        $('.wishlist span.wishlistCount').html(data.wishlistCount);
                    }
                    if (type == 'remove') {
                        $el.removeClass('wishlist-active');
                    } else {
                        $el.addClass('wishlist-active');
                        $('body').trigger('ga:event', {
                            callback: 'addToWishlist',
                            data: {data: gtmData}
                        });

                        if (window.resources.preferences.isGTMEnabled) {
                            $('body').trigger('gtm:event', {
                                callback: 'addToWishlist',
                                data: {data: gtmData}
                            });
                        }
                    }
                    displayMessageAndChangeIcon(data, icon, type);
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, icon, type);
                }
            });
        });
    }
};
